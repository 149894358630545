@font-face {
    font-family: "Quicksand";
    src: url("Quicksand_Book.otf") format("opentype");
}

@font-face {
    font-family: "Quicksand";
    font-style: oblique;
    src: url("Quicksand_Book_Oblique.otf") format("opentype");
}

@font-face {
    font-family: "Quicksand";
    font-weight: bold;
    src: url("Quicksand_Bold.otf") format("opentype");
}

@font-face {
    font-family: "Quicksand";
    font-weight: bold;
    font-style: oblique;
    src: url("Quicksand_Bold_Oblique.otf") format("opentype");
}

@font-face {
    font-family: "Quicksand";
    font-weight: lighter;
    src: url("Quicksand_Light.otf") format("opentype");
}

@font-face {
    font-family: "Quicksand";
    font-weight: lighter;
    font-style: oblique;
    src: url("Quicksand_Light_Oblique.otf") format("opentype");
}