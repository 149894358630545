@font-face {
    font-family: "Comfortaa";
    src: url("Comfortaa-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Comfortaa";
    font-weight: bold;
    src: url("Comfortaa-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Comfortaa";
    font-weight: lighter;
    src: url("Comfortaa-Light.ttf") format("truetype");
}